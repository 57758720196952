.MainPage {
  @apply h-full;
}

#mainpage-outlet-container > * {
  @apply grow;
}

#menu-mobile > * {
  @apply flex-1;
}
