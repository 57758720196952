.MenuButtonBase__desktop {
  @apply w-full h-[3rem] hidden md:flex items-center transition text-grey-800 cursor-pointer;
}

.MenuButtonBase__desktop .indicator {
  transition: width 0.15s ease-out;
}

.MenuButtonBase__desktop.selected,
.MenuButtonBase__desktop:hover,
.MenuButtonBase__desktop.selected [class^='icon'],
.MenuButtonBase__desktop:hover [class^='icon'] {
  @apply text-orange;
}

.MenuButtonBase__desktop.selected,
.MenuButtonBase__desktop:hover {
  @apply bg-orange-150;
}

.MenuButtonBase__desktop.selected .indicator,
.MenuButtonBase__desktop:hover .indicator {
  @apply w-full;
}

.MenuButtonBase__desktop .icon-wrapper {
  @apply transition-[background];
}

.MenuButtonBase__desktop.selected .icon-wrapper {
  @apply bg-orange-300;
}

.MenuButtonBase__mobile {
  @apply flex md:hidden w-full h-full justify-center items-center px-1;
}

.MenuButtonBase__mobile.selected,
.MenuButtonBase__mobile:hover {
  @apply bg-orange-150 text-orange;
}

.MenuButtonBase__mobile.selected .icon-wrapper {
  @apply bg-orange-300;
}

.MenuButtonBase__mobile.selected .icon-wrapper,
.MenuButtonBase__mobile:hover .icon-wrapper {
  @apply text-orange;
}
