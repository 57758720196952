@tailwind utilities;

.Image > * {
  @apply transition-opacity;
}

.Image:not(.Image--loaded) img {
  @apply opacity-0;
}

.Image.Image--loaded img {
  @apply opacity-100;
}

.Image:not(.Image--loaded) .Image__placeholder {
  @apply generic-placeholder;
}

.Image.Image--loaded .Image__placeholder {
  @apply opacity-0;
}
