@keyframes LogsLoadingBlock__cog_spin_cw__animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LogsLoadingBlock__cogs {
  --cog-area-size: 24px;
  width: var(--cog-area-size);
  height: var(--cog-area-size);
}

.LogsLoadingBlock__cog {
  display: relative;
}

.LogsLoadingBlock__cog_spin_cw {
  animation: LogsLoadingBlock__cog_spin_cw__animation 5s linear infinite;
  --size: calc(var(--cog-area-size) / 1.5);
  font-size: var(--size);
  align-self: end;
  justify-self: start;
}

.LogsLoadingBlock__cog_spin_ccw {
  animation: LogsLoadingBlock__cog_spin_cw__animation 3.75s linear infinite
    reverse;
  --size: calc(var(--cog-area-size) / 2);
  font-size: var(--size);
  transform-origin: center;
  justify-self: end;
  align-self: start;
}
