.DebugTable tr {
  @apply bg-grey-200;
}

.DebugTable tbody tr:nth-child(2n + 1) {
  @apply bg-white;
}

.DebugTable tbody tr {
  @apply hover:bg-blue-100;
}

.DebugTable td,
.DebugTable th {
  @apply border border-collapse;
}
