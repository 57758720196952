.NewEnvironment__form-group {
  @apply flex flex-col items-stretch gap-2;
}

.NewEnvironment__form-group--extra-gap {
  @apply gap-7;
}

.NewEnvironment__form-group__controls {
  @apply flex flex-col items-stretch gap-4;
}

.NewEnvironment__form-group__controls--no-gap {
  @apply gap-0;
}
