.modal {
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #91919186;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  width: 30%;
  min-width: 30em;
}

.modal-content > * {
  padding: 1em;
}

.modal-header {
  background-color: #ff5d394d;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
