@font-face {
  font-family: 'feather';
  src: url('../fonts/FeatherIcons/feather.svg?af986e1a647899c2c88161b955f6a567#feather')
      format('svg'),
    url('../fonts/FeatherIcons/feather.ttf?af986e1a647899c2c88161b955f6a567')
      format('truetype'),
    url('../fonts/FeatherIcons/feather.woff?af986e1a647899c2c88161b955f6a567')
      format('woff');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: feather !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: '\f101';
}
.icon-airplay:before {
  content: '\f102';
}
.icon-alert-circle:before {
  content: '\f103';
}
.icon-alert-octagon:before {
  content: '\f104';
}
.icon-alert-triangle:before {
  content: '\f105';
}
.icon-align-center:before {
  content: '\f106';
}
.icon-align-justify:before {
  content: '\f107';
}
.icon-align-left:before {
  content: '\f108';
}
.icon-align-right:before {
  content: '\f109';
}
.icon-anchor:before {
  content: '\f10a';
}
.icon-aperture:before {
  content: '\f10b';
}
.icon-archive:before {
  content: '\f10c';
}
.icon-arrow-down-circle:before {
  content: '\f10d';
}
.icon-arrow-down-left:before {
  content: '\f10e';
}
.icon-arrow-down-right:before {
  content: '\f10f';
}
.icon-arrow-down:before {
  content: '\f110';
}
.icon-arrow-left-circle:before {
  content: '\f111';
}
.icon-arrow-left:before {
  content: '\f112';
}
.icon-arrow-right-circle:before {
  content: '\f113';
}
.icon-arrow-right:before {
  content: '\f114';
}
.icon-arrow-up-circle:before {
  content: '\f115';
}
.icon-arrow-up-left:before {
  content: '\f116';
}
.icon-arrow-up-right:before {
  content: '\f117';
}
.icon-arrow-up:before {
  content: '\f118';
}
.icon-at-sign:before {
  content: '\f119';
}
.icon-award:before {
  content: '\f11a';
}
.icon-bar-chart-2:before {
  content: '\f11b';
}
.icon-bar-chart:before {
  content: '\f11c';
}
.icon-battery-charging:before {
  content: '\f11d';
}
.icon-battery:before {
  content: '\f11e';
}
.icon-bell-off:before {
  content: '\f11f';
}
.icon-bell:before {
  content: '\f120';
}
.icon-bill:before {
  content: '\f121';
}
.icon-bluetooth:before {
  content: '\f122';
}
.icon-bold:before {
  content: '\f123';
}
.icon-book-open:before {
  content: '\f124';
}
.icon-book:before {
  content: '\f125';
}
.icon-bookmark:before {
  content: '\f126';
}
.icon-box:before {
  content: '\f127';
}
.icon-briefcase:before {
  content: '\f128';
}
.icon-building:before {
  content: '\f129';
}
.icon-calendar:before {
  content: '\f12a';
}
.icon-camera-off:before {
  content: '\f12b';
}
.icon-camera:before {
  content: '\f12c';
}
.icon-cast:before {
  content: '\f12d';
}
.icon-check-circle:before {
  content: '\f12e';
}
.icon-check-square:before {
  content: '\f12f';
}
.icon-check:before {
  content: '\f130';
}
.icon-chess-king:before {
  content: '\f131';
}
.icon-chevron-down:before {
  content: '\f132';
}
.icon-chevron-left:before {
  content: '\f133';
}
.icon-chevron-right:before {
  content: '\f134';
}
.icon-chevron-up:before {
  content: '\f135';
}
.icon-chevrons-down:before {
  content: '\f136';
}
.icon-chevrons-left:before {
  content: '\f137';
}
.icon-chevrons-right:before {
  content: '\f138';
}
.icon-chevrons-up:before {
  content: '\f139';
}
.icon-chrome:before {
  content: '\f13a';
}
.icon-circle:before {
  content: '\f13b';
}
.icon-clean:before {
  content: '\f13c';
}
.icon-clipboard:before {
  content: '\f13d';
}
.icon-clock:before {
  content: '\f13e';
}
.icon-cloud-drizzle:before {
  content: '\f13f';
}
.icon-cloud-lightning:before {
  content: '\f140';
}
.icon-cloud-off:before {
  content: '\f141';
}
.icon-cloud-rain:before {
  content: '\f142';
}
.icon-cloud-snow:before {
  content: '\f143';
}
.icon-cloud:before {
  content: '\f144';
}
.icon-code:before {
  content: '\f145';
}
.icon-codepen:before {
  content: '\f146';
}
.icon-codesandbox:before {
  content: '\f147';
}
.icon-coffee:before {
  content: '\f148';
}
.icon-columns:before {
  content: '\f149';
}
.icon-command:before {
  content: '\f14a';
}
.icon-compass:before {
  content: '\f14b';
}
.icon-copy:before {
  content: '\f14c';
}
.icon-corner-down-left:before {
  content: '\f14d';
}
.icon-corner-down-right:before {
  content: '\f14e';
}
.icon-corner-left-down:before {
  content: '\f14f';
}
.icon-corner-left-up:before {
  content: '\f150';
}
.icon-corner-right-down:before {
  content: '\f151';
}
.icon-corner-right-up:before {
  content: '\f152';
}
.icon-corner-up-left:before {
  content: '\f153';
}
.icon-corner-up-right:before {
  content: '\f154';
}
.icon-cpu:before {
  content: '\f155';
}
.icon-credit-card:before {
  content: '\f156';
}
.icon-crop:before {
  content: '\f157';
}
.icon-crosshair:before {
  content: '\f158';
}
.icon-crystal-ball:before {
  content: '\f159';
}
.icon-database:before {
  content: '\f15a';
}
.icon-delete:before {
  content: '\f15b';
}
.icon-disc:before {
  content: '\f15c';
}
.icon-divide-circle:before {
  content: '\f15d';
}
.icon-divide-square:before {
  content: '\f15e';
}
.icon-divide:before {
  content: '\f15f';
}
.icon-dollar-sign:before {
  content: '\f160';
}
.icon-download-cloud:before {
  content: '\f161';
}
.icon-download:before {
  content: '\f162';
}
.icon-dribbble:before {
  content: '\f163';
}
.icon-droplet:before {
  content: '\f164';
}
.icon-edit-2:before {
  content: '\f165';
}
.icon-edit-3:before {
  content: '\f166';
}
.icon-edit:before {
  content: '\f167';
}
.icon-external-link:before {
  content: '\f168';
}
.icon-eye-off:before {
  content: '\f169';
}
.icon-eye:before {
  content: '\f16a';
}
.icon-facebook:before {
  content: '\f16b';
}
.icon-fast-forward:before {
  content: '\f16c';
}
.icon-feather:before {
  content: '\f16d';
}
.icon-figma:before {
  content: '\f16e';
}
.icon-file-minus:before {
  content: '\f16f';
}
.icon-file-pdf:before {
  content: '\f170';
}
.icon-file-plus:before {
  content: '\f171';
}
.icon-file-text:before {
  content: '\f172';
}
.icon-file:before {
  content: '\f173';
}
.icon-film:before {
  content: '\f174';
}
.icon-filter:before {
  content: '\f175';
}
.icon-flag:before {
  content: '\f176';
}
.icon-folder-minus:before {
  content: '\f177';
}
.icon-folder-plus:before {
  content: '\f178';
}
.icon-folder:before {
  content: '\f179';
}
.icon-framer:before {
  content: '\f17a';
}
.icon-frown:before {
  content: '\f17b';
}
.icon-gift:before {
  content: '\f17c';
}
.icon-git-branch:before {
  content: '\f17d';
}
.icon-git-commit:before {
  content: '\f17e';
}
.icon-git-merge:before {
  content: '\f17f';
}
.icon-git-pull-request:before {
  content: '\f180';
}
.icon-github:before {
  content: '\f181';
}
.icon-gitlab:before {
  content: '\f182';
}
.icon-globe:before {
  content: '\f183';
}
.icon-grid:before {
  content: '\f184';
}
.icon-hard-drive:before {
  content: '\f185';
}
.icon-hash:before {
  content: '\f186';
}
.icon-headphones:before {
  content: '\f187';
}
.icon-heart:before {
  content: '\f188';
}
.icon-help-circle:before {
  content: '\f189';
}
.icon-hexagon:before {
  content: '\f18a';
}
.icon-home:before {
  content: '\f18b';
}
.icon-id-card:before {
  content: '\f18c';
}
.icon-image:before {
  content: '\f18d';
}
.icon-inbox:before {
  content: '\f18e';
}
.icon-info:before {
  content: '\f18f';
}
.icon-instagram:before {
  content: '\f190';
}
.icon-italic:before {
  content: '\f191';
}
.icon-key:before {
  content: '\f192';
}
.icon-layers:before {
  content: '\f193';
}
.icon-layout:before {
  content: '\f194';
}
.icon-life-buoy:before {
  content: '\f195';
}
.icon-link-2:before {
  content: '\f196';
}
.icon-link:before {
  content: '\f197';
}
.icon-linkedin:before {
  content: '\f198';
}
.icon-list:before {
  content: '\f199';
}
.icon-loader:before {
  content: '\f19a';
}
.icon-lock:before {
  content: '\f19b';
}
.icon-log-in:before {
  content: '\f19c';
}
.icon-log-out:before {
  content: '\f19d';
}
.icon-mail:before {
  content: '\f19e';
}
.icon-map-pin:before {
  content: '\f19f';
}
.icon-map:before {
  content: '\f1a0';
}
.icon-maximize-2:before {
  content: '\f1a1';
}
.icon-maximize:before {
  content: '\f1a2';
}
.icon-meh:before {
  content: '\f1a3';
}
.icon-menu:before {
  content: '\f1a4';
}
.icon-message-circle:before {
  content: '\f1a5';
}
.icon-message-square:before {
  content: '\f1a6';
}
.icon-mic-off:before {
  content: '\f1a7';
}
.icon-mic:before {
  content: '\f1a8';
}
.icon-minimize-2:before {
  content: '\f1a9';
}
.icon-minimize:before {
  content: '\f1aa';
}
.icon-minus-circle:before {
  content: '\f1ab';
}
.icon-minus-square:before {
  content: '\f1ac';
}
.icon-minus:before {
  content: '\f1ad';
}
.icon-monitor:before {
  content: '\f1ae';
}
.icon-moon:before {
  content: '\f1af';
}
.icon-more-horizontal:before {
  content: '\f1b0';
}
.icon-more-vertical:before {
  content: '\f1b1';
}
.icon-mouse-pointer:before {
  content: '\f1b2';
}
.icon-move:before {
  content: '\f1b3';
}
.icon-multi-server:before {
  content: '\f1b4';
}
.icon-music:before {
  content: '\f1b5';
}
.icon-navigation-2:before {
  content: '\f1b6';
}
.icon-navigation:before {
  content: '\f1b7';
}
.icon-octagon:before {
  content: '\f1b8';
}
.icon-package:before {
  content: '\f1b9';
}
.icon-paperclip:before {
  content: '\f1ba';
}
.icon-pause-circle:before {
  content: '\f1bb';
}
.icon-pause:before {
  content: '\f1bc';
}
.icon-pen-tool:before {
  content: '\f1bd';
}
.icon-percent:before {
  content: '\f1be';
}
.icon-phone-call:before {
  content: '\f1bf';
}
.icon-phone-forwarded:before {
  content: '\f1c0';
}
.icon-phone-incoming:before {
  content: '\f1c1';
}
.icon-phone-missed:before {
  content: '\f1c2';
}
.icon-phone-off:before {
  content: '\f1c3';
}
.icon-phone-outgoing:before {
  content: '\f1c4';
}
.icon-phone:before {
  content: '\f1c5';
}
.icon-pie-chart:before {
  content: '\f1c6';
}
.icon-play-circle:before {
  content: '\f1c7';
}
.icon-play:before {
  content: '\f1c8';
}
.icon-plus-circle:before {
  content: '\f1c9';
}
.icon-plus-square:before {
  content: '\f1ca';
}
.icon-plus:before {
  content: '\f1cb';
}
.icon-pocket:before {
  content: '\f1cc';
}
.icon-power:before {
  content: '\f1cd';
}
.icon-printer:before {
  content: '\f1ce';
}
.icon-profile:before {
  content: '\f1cf';
}
.icon-radio:before {
  content: '\f1d0';
}
.icon-refresh-ccw:before {
  content: '\f1d1';
}
.icon-refresh-cw:before {
  content: '\f1d2';
}
.icon-repeat:before {
  content: '\f1d3';
}
.icon-rewind:before {
  content: '\f1d4';
}
.icon-rotate-ccw:before {
  content: '\f1d5';
}
.icon-rotate-cw:before {
  content: '\f1d6';
}
.icon-rss:before {
  content: '\f1d7';
}
.icon-save:before {
  content: '\f1d8';
}
.icon-scissors:before {
  content: '\f1d9';
}
.icon-search:before {
  content: '\f1da';
}
.icon-send:before {
  content: '\f1db';
}
.icon-server:before {
  content: '\f1dc';
}
.icon-settings:before {
  content: '\f1dd';
}
.icon-share-2:before {
  content: '\f1de';
}
.icon-share:before {
  content: '\f1df';
}
.icon-shield-off:before {
  content: '\f1e0';
}
.icon-shield:before {
  content: '\f1e1';
}
.icon-shopping-bag:before {
  content: '\f1e2';
}
.icon-shopping-cart:before {
  content: '\f1e3';
}
.icon-shuffle:before {
  content: '\f1e4';
}
.icon-sidebar:before {
  content: '\f1e5';
}
.icon-skip-back:before {
  content: '\f1e6';
}
.icon-skip-forward:before {
  content: '\f1e7';
}
.icon-slack:before {
  content: '\f1e8';
}
.icon-slash:before {
  content: '\f1e9';
}
.icon-sliders:before {
  content: '\f1ea';
}
.icon-smartphone:before {
  content: '\f1eb';
}
.icon-smile:before {
  content: '\f1ec';
}
.icon-speaker:before {
  content: '\f1ed';
}
.icon-square:before {
  content: '\f1ee';
}
.icon-star:before {
  content: '\f1ef';
}
.icon-stop-circle:before {
  content: '\f1f0';
}
.icon-sun:before {
  content: '\f1f1';
}
.icon-sunrise:before {
  content: '\f1f2';
}
.icon-sunset:before {
  content: '\f1f3';
}
.icon-table:before {
  content: '\f1f4';
}
.icon-tablet:before {
  content: '\f1f5';
}
.icon-tag:before {
  content: '\f1f6';
}
.icon-target:before {
  content: '\f1f7';
}
.icon-terminal:before {
  content: '\f1f8';
}
.icon-thermometer:before {
  content: '\f1f9';
}
.icon-thumbs-down:before {
  content: '\f1fa';
}
.icon-thumbs-up:before {
  content: '\f1fb';
}
.icon-toggle-left:before {
  content: '\f1fc';
}
.icon-toggle-right:before {
  content: '\f1fd';
}
.icon-tool:before {
  content: '\f1fe';
}
.icon-trash-2:before {
  content: '\f1ff';
}
.icon-trash:before {
  content: '\f200';
}
.icon-trello:before {
  content: '\f201';
}
.icon-trending-down:before {
  content: '\f202';
}
.icon-trending-up:before {
  content: '\f203';
}
.icon-triangle:before {
  content: '\f204';
}
.icon-truck:before {
  content: '\f205';
}
.icon-tv:before {
  content: '\f206';
}
.icon-twitch:before {
  content: '\f207';
}
.icon-twitter:before {
  content: '\f208';
}
.icon-type:before {
  content: '\f209';
}
.icon-umbrella:before {
  content: '\f20a';
}
.icon-underline:before {
  content: '\f20b';
}
.icon-undo:before {
  content: '\f20c';
}
.icon-unlock:before {
  content: '\f20d';
}
.icon-upload-cloud:before {
  content: '\f20e';
}
.icon-upload:before {
  content: '\f20f';
}
.icon-user-check:before {
  content: '\f210';
}
.icon-user-minus:before {
  content: '\f211';
}
.icon-user-plus:before {
  content: '\f212';
}
.icon-user-x:before {
  content: '\f213';
}
.icon-user:before {
  content: '\f214';
}
.icon-users:before {
  content: '\f215';
}
.icon-video-off:before {
  content: '\f216';
}
.icon-video:before {
  content: '\f217';
}
.icon-voicemail:before {
  content: '\f218';
}
.icon-volume-1:before {
  content: '\f219';
}
.icon-volume-2:before {
  content: '\f21a';
}
.icon-volume-x:before {
  content: '\f21b';
}
.icon-volume:before {
  content: '\f21c';
}
.icon-watch:before {
  content: '\f21d';
}
.icon-wifi-off:before {
  content: '\f21e';
}
.icon-wifi:before {
  content: '\f21f';
}
.icon-wind:before {
  content: '\f220';
}
.icon-x-circle:before {
  content: '\f221';
}
.icon-x-octagon:before {
  content: '\f222';
}
.icon-x-square:before {
  content: '\f223';
}
.icon-x:before {
  content: '\f224';
}
.icon-youtube:before {
  content: '\f225';
}
.icon-zap-off:before {
  content: '\f226';
}
.icon-zap:before {
  content: '\f227';
}
.icon-zoom-in:before {
  content: '\f228';
}
.icon-zoom-out:before {
  content: '\f229';
}
