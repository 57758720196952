.SubMenuButton__desktop {
  @apply transition-colors hidden md:flex gap-4 items-center pl-16 py-2;
}

.SubMenuButton__icon-wrapper {
  @apply rounded-md w-6 h-6 flex items-center justify-center;
}

.SubMenuButton--selected .SubMenuButton__desktop,
.SubMenuButton--selected .SubMenuButton__mobile {
  @apply text-orange bg-grey-200;
}

.SubMenuButton--selected .SubMenuButton__icon-wrapper {
  @apply bg-orange-300;
}

.SubMenuButton__mobile {
  @apply h-full flex md:hidden items-center justify-center p-1;
}

.SubMenuButton__desktop,
.SubMenuButton__mobile {
  @apply transition-colors text-grey-800 hover:text-orange hover:bg-grey-200;
}
