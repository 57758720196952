.MailButton__read-only {
  @apply stack border border-gray-300 rounded-small bg-gray-200 pl-2 pr-1 py-1 gap-2;
}

.MailButton__read-only p {
  @apply py-2;
}

.MailButton__copy-button {
  @apply justify-self-end self-start;
}
