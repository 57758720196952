.Workplace .ActionBlock .action-buttons {
  @apply grid 
    grid-flow-row
    gap-x-4
    w-full

    whitespace-nowrap;

  grid-template-columns: repeat(auto-fit, minmax(180px, min-content));
  justify-items: stretch;
}

.Workplace .ActionBlock .action-buttons > * {
  @apply overflow-hidden text-ellipsis;
}

.Workplace .ActionBlock {
  @apply grow;
}
