.Workplace .MainPageContainer {
  @apply grid gap-6;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-template-areas:
    'actions-costs'
    'informations'
    'historic'
    'details';
}

.Workplace .MainPageContainer.no-actions {
  grid-template-rows: repeat(3, min-content);
  grid-template-areas:
    'informations'
    'historic'
    'details';
}

.Workplace .MainPageContainer.single-element {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

@media (min-width: 1280px) {
  .Workplace .MainPageContainer {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas:
      'actions-costs actions-costs'
      'informations historic'
      'details details';
  }

  .Workplace .MainPageContainer.no-actions {
    grid-template-rows: repeat(2, min-content);
    grid-template-areas:
      'informations historic'
      'details details';
  }

  .Workplace .MainPageContainer.single-element {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.Workplace__actions-costs {
  grid-area: actions-costs;
  @apply flex gap-6 flex-col sm:flex-row;
}

.Workplace__informations {
  grid-area: informations;
}

.Workplace__historic {
  grid-area: historic;
}

.Workplace__details {
  grid-area: details;
}

.Workplace .MainPageContainer table {
  @apply font-heebo text-smd text-grey-700 font-light;
}

.Workplace .MainPageContainer table th {
  @apply pt-2 pb-4;
}

.Workplace
  .MainPageContainer
  table
  th:not([class='text-center']):not([class='text-right']) {
  @apply text-left;
}

.Workplace .MainPageContainer table tbody th {
  @apply font-normal;
}

.Workplace .MainPageContainer table tbody td,
.Workplace .MainPageContainer table tbody th {
  @apply py-2;
}

.Workplace .MainPageContainer table tbody td:not(:first-child) {
  @apply pl-2;
}

.Workplace .MainPageContainer table tbody tr:not(:last-child) {
  @apply border-b;
}

.Workplace .MainPageContainer .vm-grid-wrapper {
  @apply w-full overflow-x-auto;
}

.Workplace .MainPageContainer .vm-grid {
  @apply grid w-full text-smd font-light text-grey-700 gap-x-4;
  grid-template-columns: repeat(4, minmax(min-content, 100%)) min-content;
}

.Workplace .MainPageContainer .vm-grid > * {
  @apply flex items-center;
}

.Workplace .MainPageContainer .vm-grid__title {
  @apply font-semibold pb-4 pr-4;
}

.Workplace .MainPageContainer .vm-grid .cell > span {
  max-width: 450px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vm-details-area {
  display: grid;
  gap: 2.5ch 2.5rem;
}

@media all and (min-width: 800px) {
  .vm-name {
    grid-area: name;
  }

  .vm-description {
    grid-area: desc;
  }

  .vm-instance-type {
    grid-area: type;
  }

  .vm-ssh {
    grid-area: ssh;
  }

  .vm-vcpu {
    grid-area: vcpu;
  }

  .vm-ram {
    grid-area: ram;
  }

  .vm-disk {
    grid-area: disk;
  }

  .vm-details-area {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'name name name desc desc desc'
      'type type type desc desc desc'
      'ssh  ssh  ssh  ssh  ssh  ssh'
      'vcpu vcpu ram  ram  disk disk';
  }

  .vm-details-area.vm-details-area--no-description {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'name name name type type type'
      'ssh  ssh  ssh  ssh  ssh  ssh'
      'vcpu vcpu ram  ram  disk disk';
  }
}
