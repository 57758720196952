.ComponentLibrary {
  @apply p-2;
}

.ComponentLibrary .block {
  @apply shadow-md rounded border p-2;
  @apply bg-grey-200;
}

.ComponentLibrary h2:not(.Title) {
  @apply text-xl font-medium font-raleway;
}

.ComponentLibrary h3:not(.Title) {
  @apply text-lg;
}

.ComponentLibrary h2:not(.Title),
.ComponentLibrary h3:not(.Title) {
  @apply flex gap-2 items-baseline pt-8 pb-4;
}

.ComponentLibrary h2:not(.Title):first-child,
.ComponentLibrary h3:not(.Title):first-child {
  @apply pt-0;
}

.ComponentLibrary h2:not(.Title) pre,
.ComponentLibrary h3:not(.Title) pre {
  @apply text-grey text-sm;
}
