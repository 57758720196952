main > header {
  text-align: center;
  font: normal normal medium 17px/25px Heebo;
  letter-spacing: 0px;
  color: #43425d;
}

main > header > div {
  flex-grow: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
main > header > div:first-child span {
  display: flex;
  align-items: center;
  height: 100%;
}

main > header > div:first-child span.selected,
main > header > div:first-child span:hover {
  box-shadow: inset 0 -2px #ff5c39;
  transition-duration: 500ms;
  transition-property: box-shadow;
  color: black;
  transition-duration: 500ms;
  transition-property: color;
}

main > header button {
  border-radius: 20px;
  background-color: #dee2ff;
  height: 3em;
}
