@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('header.css');
@import url('modal.css');
@import url('table.css');
@import url('feather-icons.css');

@font-face {
  font-family: 'Heebo';
  src: url('../fonts/Heebo/Heebo-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Heebo', 'Helvetica', 'Raleway', 'Dosis', 'Arial';
  font-size: 16px;
  overflow-x: hidden;
}

#root {
  height: 100vh;
}

#wrapper {
  background-color: #f5f7f9;
  display: flex;
  width: 100%;
  height: auto !important; /* used to stop material design "height: 100%" */
  min-height: 100vh;
}

p,
ul {
  color: rgb(56, 56, 56);
}

html {
  @apply bg-gray-100;
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  display: block;
}
