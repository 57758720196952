.Title {
  @apply font-raleway;
}

h1.Title {
  @apply text-3xl;
  @apply font-bold;
}

h2.Title {
  @apply text-xl;
  @apply font-bold;
}

h3.Title {
  @apply text-lg;
  @apply font-bold;
  @apply text-grey-800;
}

h4.Title {
  @apply text-md;
  @apply font-semibold;
  @apply text-grey-700;
}

h5.Title {
  @apply text-md;
  @apply font-medium;
  @apply text-grey-700;
}

h6.Title {
  @apply text-md;
  @apply font-light;
  @apply text-grey-700;
}
