.AdminProject .ActionBlock .action-buttons {
  @apply grid 
    grid-flow-col 
    gap-x-2
    grid-cols-1
    grid-rows-6

    whitespace-nowrap;
}

@media (min-width: 900px) {
  /* Custom breakpoints length preventing to use tailwind convenient classes */
  .AdminProject .ActionBlock .action-buttons {
    @apply grid-cols-2 grid-rows-3;
  }
}

@media (min-width: 1280px) {
  .AdminProject .ActionBlock .action-buttons {
    @apply grid-cols-3 grid-rows-2;
  }
}

.AdminProject .ActionBlock .action-buttons > * {
  @apply overflow-hidden text-ellipsis;
}

.AdminProject .ActionBlock .action-buttons button {
  @apply w-full;
}
