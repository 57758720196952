.Support-layout {
  @apply flex flex-col xl:flex-row gap-4;
}

.Support-layout__explanations {
  @apply grow;
}

.Support p,
.Support h1,
.Support h2,
.Support h3,
.Support h4,
.Support h5,
.Support h6 {
  max-width: 700px;
}

.Support-layout__explanations .Title {
  @apply py-2;
}
